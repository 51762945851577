import { forwardRef } from 'react';
/* eslint-disable @nx/enforce-module-boundaries */
import { Text } from '@mybridge/ui/text';
import { getUserFullName, getUserFirstName } from 'v4/lib/commons';
import { Box, Image, Link, Popover } from '@mybridge/ui';
import { InsightPopover } from 'v4/components/post/insight-popover';
import { useSelector } from 'react-redux';

export const PostFeelingAndTagsText = forwardRef(
  (
    { feeling, tagged, imgProps, companyDetails, ...props },
    ref
  ) => {
  const { userProfileInfo } = useSelector((state) => state.userProfile);

    return (
      <>
        {feeling?.name ? (
          <>
            <Text display="inline" color="brandPrimary.500" fontWeight="medium">
              {companyDetails
                ? companyDetails?.name
                : userProfileInfo?.display_name}{' '}
            </Text>
            <Text color="brandPrimary.500" display="inline">
              {' is '}
            </Text>
            <Image
              mb={'-1'}
              display="inline"
              src={feeling?.file}
              w="6"
              h="6"
              {...imgProps}
            />
            <Text color="brandPrimary.500" display="inline">
              {` ${feeling?.type?.toLowerCase?.()} ${feeling?.name}`}
            </Text>
          </>
        ) : (
          ``
        )}
        {tagged?.length ? (
          <>
            <Text display="inline" color="brandPrimary.500" fontWeight="medium">
              {companyDetails
                ? companyDetails?.name
                : userProfileInfo?.display_name}{' '}
            </Text>
            <Text
              color="#3D5A80"
              display="inline"
              style={{
                fontSize: '15px',
                fontWeight: '300',
                lineHeight: '120%',
              }}
            >
              {!feeling && !feeling?.name ? ' is with ' : ' with '}
            </Text>
            <Text
              as={Link}
              href={'/p/' + tagged?.[0]?.slug}
              target="_blank"
              fontWeight="500"
              color="#3D5A80"
              display="inline"
              fontSize="15px"
            >
              {` ${getUserFirstName(tagged?.[0])}`}
            </Text>
            {tagged?.length > 1 ? <Text display="inline">{' and '}</Text> : ''}
            {tagged?.length > 1 ? (
              <InsightPopover
                triggerProps={{ display: 'inline' }}
                content={
                  <>
                    {tagged?.slice?.(1)?.map?.((t, tIndex) => (
                      <Text color="white" key={tIndex}>
                        {getUserFirstName(t)}
                      </Text>
                    ))}
                  </>
                }
              >
                <Text
                  textDecoration="underline"
                  color="brandPrimary.500"
                  display="inline"
                  fontWeight="500"
                  fontSize="15px"
                >
                  {`${tagged?.length - 1} other${
                    tagged?.length > 2 ? 's' : ''
                  }`}
                </Text>
              </InsightPopover>
            ) : (
              ''
            )}
          </>
        ) : (
          ``
        )}
      </>
    );
  }
);
