/* eslint-disable @nx/enforce-module-boundaries */
import { CheckMark1, ChevronDown, NewGlobe } from '@mybridge/icons';
import { HStack } from '@mybridge/ui/layout';
import {
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
} from '@mybridge/ui/menu';

import { Text } from '@mybridge/ui/text';
import { useContext, useState } from 'react';
import { PostVisibilityOptions } from '../../../snippets/dashboard/constants';
import { PostFormContext } from '../context';
import { Portal } from '@mybridge/ui';

export const CommentControlMenu = () => {
  const {
    replyControl = PostVisibilityOptions?.[0]?.value,
    setReplyControl,
    isControlMenuOpen,
    setIsControlMenuOpen,
    setIsReactionMenuOpen
  } = useContext(PostFormContext);
  const replyControl_ = PostVisibilityOptions?.find?.(
    (rc) => rc?.value === replyControl
  );
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Who can reply?');

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  return (
    <>
      {isControlMenuOpen ? (
        <HStack>
          {replyControl_?.icon}
          <Text
            onClick={() => {
              setMenuOpen(true);
              setIsControlMenuOpen(!isControlMenuOpen);
              setIsReactionMenuOpen(isControlMenuOpen);
            }}
          >
            {selectedOption}
          </Text>
        </HStack>
      ) : (
        <Menu zIndez="1" onClose={() => setMenuOpen(false)} isOpen={menuOpen}>
          <MenuButton
            variant="transparent"
            // border="1px solid"
            // borderColor="gray.200"
            // padding="0.2rem 0.5rem"
            // height="auto"
            color="#5B5B5B"
            // borderRadius="5"
            // fontSize="14px"
            fontSize="sm"
            width="202px"
            height="40px"
            padding="10px 14px 10px 14px"
            gap="10px"
            borderRadius="20px"
            border="1px 1px 0px 1px"
            opacity="0px"
            boxShadow="0px 2px 6px 0px #00000033"
            onClick={() => {
              setMenuOpen(!menuOpen);
              setIsControlMenuOpen(isControlMenuOpen);
              setIsReactionMenuOpen(!isControlMenuOpen);
            }}
          >
            <HStack>
              {replyControl_?.icon}
              <Text color="gray.700">
                {/* {replyControl_?.title ?? 'Everyone'} can reply */}
                {/* Who can reply? */}
                <Text color="gray.600">{selectedOption}</Text>
              </Text>
              {/* <ChevronDown /> */}
            </HStack>
          </MenuButton>

          <Portal>
            <MenuList
              zIndex={10000}
              shadow="base"
              position="absolute"
              top="-50px"
              minWidth="auto"
              width="202px"
              borderRadius="20px 20px 10px 10px"
            >
              <MenuGroup>
                <HStack
                  padding="6px 12px"
                  borderBottom="1px solid #E4E6EB"
                  onClick={() => {
                    setSelectedOption('Who can reply?'); // Update the selected option text
                    handleMenuClose(); // Close the menu
                  }}
                >
                  {replyControl_?.icon}
                  <Text color="gray.700">
                    Who can reply?
                  </Text>
                </HStack>
                {PostVisibilityOptions?.map((vo, voind) => (
                  <MenuItem
                    key={voind}
                    onClick={() => {
                      setReplyControl(vo?.value);
                      setSelectedOption(vo?.title);
                    }}
                    icon={vo.icon}
                  >
                    <HStack>
                      <Text flex={1}>{vo.title}</Text>
                      {vo?.value === replyControl ? <CheckMark1 /> : ''}
                    </HStack>
                  </MenuItem>
                ))}
              </MenuGroup>
            </MenuList>
          </Portal>
        </Menu>
      )}
    </>
  );
};
